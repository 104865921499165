<template>
    <div class="error-page">
        <div class="content-con">
            <img
                :src="src"
                alt="404"
            >
            <back-btn-group class="back-btn-group" />
        </div>
    </div>
</template>

<script>
import './error.scss';
import backBtnGroup from './back-btn-group.vue';

export default {
    name: 'Error404',
    components: {
        backBtnGroup,
    },
    props: {
        code: String,
        desc: String,
        src: String,
    },
};
</script>
